// src/pages/puzzle.js
import React, { useState } from 'react';
import styled from 'styled-components';
import Layout from '../components/layout';
import { Helmet } from 'react-helmet';
import { navigate } from 'gatsby';

const PuzzleContainer = styled.div`
  max-width: 800px;
  margin: 0 auto;
  padding: 50px 20px;
  text-align: center;
`;

const Title = styled.h1`
  font-size: 2.5rem;
  margin-bottom: 20px;
`;

const Clue = styled.p`
  font-size: 1.25rem;
  margin-bottom: 20px;
`;

const Input = styled.input`
  font-size: 1.25rem;
  padding: 10px;
  margin-bottom: 20px;
  width: 100%;
  max-width: 300px;
  text-align: center;
`;

const Button = styled.button`
  font-size: 1.25rem;
  padding: 10px 20px;
  background-color: var(--green);
  color: var(--navy);
  border: none;
  cursor: pointer;
`;

const Puzzle = () => {
  const [answer, setAnswer] = useState('');
  const correctAnswer = 'women'; // Define the correct answer here

  const handleSubmit = () => {
    if (answer.toLowerCase() === correctAnswer.toLowerCase()) {
      navigate('/secret-revealed');
    } else {
      alert('Wrong answer, try again!');
    }
  };

  return (
    <Layout>
      <Helmet>
        <title>Puzzle | My Website</title>
      </Helmet>
      <PuzzleContainer>
        <Title>Solve the Puzzle</Title>
        <Clue>Clue 1: This is your first clue.</Clue>
        <Clue>Clue 2: This is your second clue.</Clue>
        <Clue>Clue 3: This is your third clue.</Clue>
        <Input
          type="text"
          placeholder="Enter the answer"
          value={answer}
          onChange={(e) => setAnswer(e.target.value)}
        />
        <Button onClick={handleSubmit}>Submit</Button>
      </PuzzleContainer>
    </Layout>
  );
};

export default Puzzle;
